import { useCurrentLocale } from 'next-i18n-router/client';

import i18nConfig from '@/i18nConfig';
import { Lang } from '@/types/locales';

export const useLang = () => {
  const locale = useCurrentLocale(i18nConfig);

  return (locale || i18nConfig.defaultLocale) as Lang;
};
