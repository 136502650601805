import React, { Suspense } from 'react';
import PathnameBasedPageSetter from '@/components/organisms/AlternateLanguageSetter/partials/PathnameBasedPageSetter';
import LoadingContent from '@/components/molecules/LoadingContent';
import Logo from '/public/assets/svg/logo.svg';
const NotFound = () => <section className="o-container o-container--xl my-16 flex flex-col items-center justify-center lg:my-40" data-sentry-component="NotFound" data-sentry-source-file="not-found.tsx">
    <Suspense fallback={<LoadingContent />} data-sentry-element="Suspense" data-sentry-source-file="not-found.tsx">
      <PathnameBasedPageSetter data-sentry-element="PathnameBasedPageSetter" data-sentry-source-file="not-found.tsx" />
    </Suspense>

    <Logo className="h-8 w-40 lg:h-16 lg:w-80" data-sentry-element="Logo" data-sentry-source-file="not-found.tsx" />
    <h1 className="mt-6 text-center text-body-8 lg:text-h3">
      Sorry! That page doesn&apos;t seem to exist.
    </h1>
  </section>;
export default NotFound;