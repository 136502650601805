import Image from 'next/image';
import { EllipseTimerImage } from '@/public/assets/images';
const LoadingContent = () => {
  return <section className="o-container o-container--xl my-12 flex items-center justify-center !px-4 !py-40 lg:!px-0 lg:!py-60" data-sentry-component="LoadingContent" data-sentry-source-file="index.tsx">
      <div className="relative h-20 w-20 lg:h-32 lg:w-32">
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
          <Image src={EllipseTimerImage} layout="fill" quality={100} alt="loading image" className="animate-spin-slow object-cover" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        </div>
      </div>
    </section>;
};
export default LoadingContent;