'use client';

import React, { useMemo } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import i18nConfig from '@/i18nConfig';
import { useLang } from '@/hooks/useLang';
import getAlternateLanguagesPaths from '@/utils/locales/getAlternateLanguagePath';
import AlternateLanguageSetter from '../..';
import { PathnameBasedPageSetterProps } from './types';
const PathnameBasedPageSetter = ({
  pathExtractor
}: PathnameBasedPageSetterProps) => {
  const lang = useLang();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const alternateLanguagePaths = useMemo(() => {
    const pathnameWithoutLocale = lang ? pathname.replace(`/${lang}`, '') : pathname;
    const defaultPathExtractor = () => {
      const query = searchParams.toString();
      return query ? `${pathnameWithoutLocale}?${query}` : pathnameWithoutLocale;
    };
    return getAlternateLanguagesPaths({
      alternateLanguages: i18nConfig.locales as string[],
      pathExtractor: pathExtractor || defaultPathExtractor
    });
  }, [lang, pathExtractor, pathname, searchParams]);
  return <AlternateLanguageSetter items={alternateLanguagePaths} data-sentry-element="AlternateLanguageSetter" data-sentry-component="PathnameBasedPageSetter" data-sentry-source-file="index.tsx" />;
};
export default PathnameBasedPageSetter;