export enum RadioMode {
  Default = 'default',
  SurveyQuestion = 'surveyQuestion',
}

export interface RadioBaseProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'type' | 'as' | 'ref' | 'mode' | 'label'> {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  error?: string;
  mode?: RadioMode;
  label?: string | React.ReactNode;
  additionalText?: string;
}
