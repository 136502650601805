import { AlternateLanguage } from '@prismicio/client';

import i18nConfig from '@/i18nConfig';

import { AlternateLanguagePathItem } from './types';

import { addLocale } from '.';

const getAlternateLanguagesPaths = <T extends AlternateLanguage | string>({
  alternateLanguages,
  pathExtractor,
  defaultPath = '/',
}: {
  alternateLanguages: T[];
  pathExtractor: (languageOrLanguageMetadata: T) => string;
  defaultPath?: string;
}): AlternateLanguagePathItem[] =>
  i18nConfig.locales.map((locale) => {
    const alternateLanguage = alternateLanguages.find((alternateLanguage) => {
      if (typeof alternateLanguage == 'string') {
        return alternateLanguage === locale;
      }

      return alternateLanguage.lang === locale;
    });

    // If there's no alternate language
    if (!alternateLanguage) {
      return {
        lang: locale,
        path: addLocale(defaultPath, locale),
      };
    }

    const path = addLocale(pathExtractor(alternateLanguage), locale);

    return {
      lang: locale,
      path: addLocale(path, locale),
    };
  });

export default getAlternateLanguagesPaths;
