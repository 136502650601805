import urlJoin from 'url-join';

import i18nConfig, { LOCALES } from '@/i18nConfig';
import { Lang } from '@/types/locales';
import { getCountryFromLocale } from '@/app/api/utils';

export const getCountryCodeFromLang = (lang: string): string => {
  return lang.split('-')[1].toUpperCase();
};

export const getCountryLocales = <T>(
  country: string,
  languages: T[],
  languageExtractor: (item: T) => string,
): string[] =>
  languages.reduce<string[]>((acc, language) => {
    const extractedLanguage = languageExtractor(language);

    if (getCountryCodeFromLang(extractedLanguage) === country) {
      acc.push(extractedLanguage);
    }

    return acc;
  }, []);

export const addLocale = (
  path: string,
  locale: string,
  defaultLocale = i18nConfig.defaultLocale,
) => {
  if (locale === defaultLocale) {
    return path;
  }

  const lowerCasePath = path.toLowerCase();
  const lowerCaseLocale = locale.toLowerCase();

  if (
    lowerCasePath.startsWith(lowerCaseLocale) ||
    lowerCasePath.startsWith(`/${lowerCaseLocale}`)
  ) {
    return path;
  }

  return urlJoin('/', locale, path);
};

export const getLocationName = (lang: Lang) => getCountryFromLocale(lang);

export const checkIfLangIsEnabled = (lang: string) => Object.values(LOCALES).includes(lang);

export const isLang = (value: string): value is Lang => Object.values(LOCALES).includes(value);
