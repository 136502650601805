import { NextRequest, NextResponse } from 'next/server';
import { Schema, ValidationError, Validator } from 'jsonschema';

import { makeRequest } from '@/utils';
import { LOCALES } from '@/i18nConfig';
import { Lang } from '@/types/locales';
import { isLang } from '@/utils/locales';

import { SURVEY_PAGE_RATING_QUERY_PARAM } from '../[lang]/survey/partials/SurveyForm/consts';

import { APP_LOCALE_HEADER, RECAPTCHA_HEADER, VERIFY_TOKEN_ENDPOINT } from './consts';
import { NextHandler, RequestPartsSchemas } from './types';

export const withReCAPTCHA =
  (handler: NextHandler): NextHandler =>
  async (request) => {
    const { headers } = request;
    const recaptchaTokenHeader = headers.get(RECAPTCHA_HEADER);
    const [recaptchaToken] = Array.isArray(recaptchaTokenHeader)
      ? recaptchaTokenHeader
      : [recaptchaTokenHeader];

    if (!recaptchaToken) {
      return new NextResponse('Invalid recaptcha token', { status: 400 });
    }

    try {
      await makeRequest(
        `${VERIFY_TOKEN_ENDPOINT}?${new URLSearchParams({ response: recaptchaToken, secret: process.env.RECAPTCHA_SECRET_KEY as string })}`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      );
    } catch {
      return new NextResponse('Invalid recaptcha token', { status: 400 });
    }

    return handler(request);
  };

export const getXLcLocaleHeader = (request: Request): Lang => {
  const { headers } = request;
  const headerValue = headers.get(APP_LOCALE_HEADER);

  if (!headerValue || !isLang(headerValue)) {
    return LOCALES.EN_CA as Lang;
  }

  if (headerValue === LOCALES.FR_CA) {
    return LOCALES.EN_CA as Lang;
  }

  return headerValue;
};

export const validateRequestPart = (
  validator: Validator,
  schema: Schema | undefined,
  requestPart: unknown,
  requestPartName: string,
): ValidationError[] => {
  if (!schema) {
    return [];
  }

  if (requestPartName === 'UrlParams' && typeof requestPart === 'object' && requestPart !== null) {
    const params = requestPart as Record<string, string | number>;

    if (params[SURVEY_PAGE_RATING_QUERY_PARAM]) {
      params[SURVEY_PAGE_RATING_QUERY_PARAM] = Number(params[SURVEY_PAGE_RATING_QUERY_PARAM]);
    }
  }

  return validator.validate(
    { [requestPartName]: requestPart },
    {
      type: 'object',
      properties: {
        [requestPartName]: schema,
      },
      required: [requestPartName],
    },
  ).errors;
};

export const withValidators =
  (schemas: RequestPartsSchemas) =>
  (handler: NextHandler): NextHandler => {
    return async (request: NextRequest): Promise<NextResponse> => {
      const url = new URL(request.url);
      const searchParams = Object.fromEntries(url.searchParams.entries());

      const validator = new Validator();
      const errors = [
        ...validateRequestPart(validator, schemas.body, request, 'RequestBody'),
        ...validateRequestPart(validator, schemas.query, searchParams, 'QueryString'),
        ...validateRequestPart(validator, schemas.urlParams, searchParams, 'UrlParams'),
      ];

      if (errors.length) {
        return NextResponse.json({ errors }, { status: 400 });
      }

      return handler(request);
    };
  };

export const getCountryFromLocale = (locale: Lang): string => {
  switch (locale) {
    case LOCALES.EN_CA:
    case LOCALES.FR_CA:
      return 'Canada';
    case LOCALES.EN_AU:
      return 'Australia';
    case LOCALES.EN_NZ:
      return 'New Zealand';
    case LOCALES.EN_HK:
    case LOCALES.ZH_HK:
      return 'Hong Kong';
    default:
      return 'Canada';
  }
};
