'use client';

import { useSearchParams } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import Button from '@/components/atoms/Button';
import SurveyQuestion from '@/components/molecules/SurveyQuestion';
import NotFound from '@/app/[lang]/not-found';
import { SURVEY_PAGE_TRANSACTION_ID_QUERY_PARAM } from './consts';
import { useForm, useSurveyOptions } from './hooks';
const SurveyForm = () => {
  const {
    t
  } = useTranslation();
  const searchParams = useSearchParams();
  const transactionId = searchParams.get(SURVEY_PAGE_TRANSACTION_ID_QUERY_PARAM);
  const {
    handleSubmit,
    onChange,
    selectedOption,
    isLoading,
    error
  } = useForm(transactionId);
  const ratingOptions = useSurveyOptions();
  if (!transactionId) {
    return NotFound();
  }
  return <form className="mt-16 px-5" onSubmit={handleSubmit} data-sentry-component="SurveyForm" data-sentry-source-file="index.tsx">
      <div className="flex w-full flex-col items-center">
        <SurveyQuestion<number> name="rating" label={t('surveyForm.label')} options={ratingOptions} {...{
        selectedOption,
        onChange
      }} data-sentry-element="SurveyQuestion" data-sentry-source-file="index.tsx" />
        <Button type="submit" className="mt-5 w-full lg:max-w-40" disabled={isLoading} data-sentry-element="Button" data-sentry-source-file="index.tsx">
          {t('surveyForm.submit')}
        </Button>
      </div>
      {error && <p className="text-center text-error-300">{t('surveyForm.error')}</p>}
    </form>;
};
export default SurveyForm;