import React from 'react';
import clsx from 'clsx';
import RadioBase from '../radioBase';
import { RadioBaseProps, RadioMode } from '../types';
const SurveyQuestionRadio: React.FC<RadioBaseProps> = ({
  label,
  additionalText,
  ...props
}) => <RadioBase {...{
  label,
  ...props
}} mode={RadioMode.SurveyQuestion} data-sentry-element="RadioBase" data-sentry-component="SurveyQuestionRadio" data-sentry-source-file="index.tsx">
    <div className={clsx('flex w-full items-center rounded-md p-3', 'bg-gray-200 transition-colors', 'relative cursor-pointer hover:bg-gray-300')}>
      <div className="flex flex-row items-center">
        <span className={clsx('relative mr-2 h-4 w-4 flex-shrink-0 rounded-full border border-primary-600', 'before:absolute before:left-1/2 before:top-1/2 before:h-full before:w-full before:-translate-x-1/2 before:-translate-y-1/2 before:transform before:rounded-full before:bg-white before:content-[""]', 'after:absolute after:left-1/2 after:top-1/2 after:h-2 after:w-2 after:-translate-x-1/2 after:-translate-y-1/2 after:transform after:rounded-full after:bg-primary-600 after:content-[""]', props.checked ? 'after:scale-100' : 'after:scale-0')} />
        <p className="text-body-7">{label}</p>
      </div>
      <p className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center text-caption-2">
        {additionalText}
      </p>
    </div>
  </RadioBase>;
export default SurveyQuestionRadio;