'use client';

import React, { useEffect } from 'react';
import { useAlternateLanguages } from '@/contexts/AlternateLanguagesContext/hooks';
import { AlternateLanguageSetterProps } from './types';
const AlternateLanguageSetter: React.FC<AlternateLanguageSetterProps> = ({
  items
}) => {
  const {
    setItems
  } = useAlternateLanguages();
  useEffect(() => {
    setItems(items);
    return () => {
      setItems([]);
    };
  }, [items, setItems]);
  return null;
};
export default AlternateLanguageSetter;