import React from 'react';
import SurveyQuestionRadio from '@/components/atoms/Radios/SurveyQuestionRadio';
import { SurveyQuestionProps } from './types';
function SurveyQuestion<T extends string | number = string | number>({
  label,
  options = [],
  name,
  onChange,
  selectedOption
}: SurveyQuestionProps<T>): JSX.Element {
  return <div className="w-full lg:max-w-[512px]" data-sentry-component="SurveyQuestion" data-sentry-source-file="index.tsx">
      <p className="mb-6 text-center text-lg font-semibold text-gray-700">{label}</p>
      <ul className="grid gap-2">
        {options.map(option => {
        const {
          value,
          label: optionLabel,
          additionalText
        } = option;
        const checked = value === selectedOption?.value;
        return <li key={value}>
              <SurveyQuestionRadio {...{
            name,
            value,
            checked,
            additionalText
          }} label={optionLabel} onChange={() => onChange?.(option)} />
            </li>;
      })}
      </ul>
    </div>;
}
export default SurveyQuestion;